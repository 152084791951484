import * as React from "react";
import ReactMarkdown, { defaultUrlTransform } from "react-markdown";
import { H1, H2, H3, H4 } from "./Texts";

export function MarkdownTw({ columns, children: markdownContent }: { columns?: boolean; children: any }) {
    const linkRenderer = (props: any) => {
        const { href, children } = props;
        const target = href.includes("_blank") ? "_blank" : "";
        const cleanedHref = target === "_blank" ? href.replace(/%7B:target=%22(_blank)%22%7D$/, "") : href;

        return (
            <a href={cleanedHref} target={target}>
                {children}
            </a>
        );
    };

    return (
        <div
            className={`*:mb-2 last:*:mb-0 [&_img]:inline-block [&>p:empty]:pt-2 md: ${columns ? "columns-2" : "columns-auto"}`}
        >
            <ReactMarkdown
                urlTransform={(url) => (url.startsWith("tel:") ? url : defaultUrlTransform(url))}
                components={{
                    h1: ({ ...props }) => <H1 {...props} />,
                    h2: ({ ...props }) => <H2 {...props} />,
                    h3: ({ ...props }) => <H3 {...props} />,
                    h4: ({ ...props }) => <H4 {...props} />,
                    ul: ({ ...props }) => (
                        <ul className="list-disc list-inside marker:text-cherryDark marker:text-large " {...props} />
                    ),
                    li: ({ ...props }) => <li className="pl-2" {...props} />,
                    ol: ({ ...props }) => (
                        <ol
                            className="ml-6 leading-8 list-decimal marker:text-cherryDark marker:font-bold marker:mr-8"
                            {...props}
                        />
                    ),
                    em: ({ ...props }) => <em className="italic" {...props} />,
                    strong: ({ ...props }) => <strong className="font-bold" {...props} />,
                    a: linkRenderer,
                }}
            >
                {markdownContent}
            </ReactMarkdown>
        </div>
    );
}
