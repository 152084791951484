import * as React from "react";
import { ShoutoutComponent as ShoutoutProps } from "movestic-blocks/components/__generated__/graphql";
import { MarkdownTw } from "movestic-core/tailwind/Markdown";

export const Shoutout: React.FunctionComponent<ShoutoutProps> = ({ text2, icon, color }) => {
    if (!text2) {
        return null;
    }
    const border = color === "#DD7F9B" ? "border-cherryBloom" : "border-lightGray";
    const backgroundColor = color !== "#DD7F9B" ? { backgroundColor: color } : {};

    return (
        <div className={`flex items-center border ${border} rounded-lg p-6 text-lightBlack`} style={backgroundColor}>
            {icon.filename && (
                <img src={icon.filename} alt={icon.alt} width={24} height={24} className="self-start mr-4" />
            )}
            {text2 && <MarkdownTw>{text2}</MarkdownTw>}
        </div>
    );
};
