"use client";
import * as React from "react";
import {
    ContactlistComponent as ContactListModel,
    ContactitemComponent as ContactItemModel,
} from "movestic-blocks/components/__generated__/graphql";
import { BrokerContactMapping, RegionContactModelPerson } from "app-core/utils/partnerContactPerson";
import Link from "next/link";
import { useEffect } from "react";
import { H2, H4 } from "movestic-core/tailwind/Texts";
import { MarkdownTw } from "movestic-core/tailwind/Markdown";

export const ContactList = (props: ContactListModel) => {
    const [brokerContacts, setBrokerContacts] = React.useState<RegionContactModelPerson[]>([]);
    const isBrokerContacts = props.customList === "brokercontacts";

    useEffect(() => {
        async function fetchBrokerContacts() {
            let url = "/api/brokerInfo/regioncontactlist";
            url = url.replace(/[?&]$/, "");
            const options = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            };

            const result = await fetch(url, options);

            if (result.ok) {
                const data = await result.json();
                setBrokerContacts(data);
            }
        }

        if (isBrokerContacts) {
            fetchBrokerContacts();
        }
    }, [isBrokerContacts]);

    const groupedBrokerContacts = groupBrokerContacts(
        brokerContacts?.map((x) => BrokerContactMapping(x.email, x.name)).filter((x) => x),
    );

    const contacts = isBrokerContacts ? groupedBrokerContacts : convertToRegionContactModelPerson(props.items);

    return (
        <div className="justify-center">
            <H2 className="text-center">{props.heading}</H2>
            <div className={`grid grid-cols-1 gap-6 md:grid-cols-3`}>
                {contacts?.map((contact, index) => (
                    <div className={`flex gap-6 justify-start`} key={index}>
                        <div>
                            <img
                                src={
                                    contact.img
                                        ? `${contact.img}/m/100x100/smart`
                                        : "/static/images/broker-contact-person/jane.doe.svg"
                                }
                                alt="Partner kontakt"
                                width={100}
                                height={100}
                                className={`rounded-full object-cover`}
                            />
                        </div>
                        <div>
                            <div className="font-bold uppercase text-mini">{contact.regionName}</div>
                            <H4>{contact.name}</H4>
                            <div>{contact.phone}</div>
                            {contact.email && <Link href={`mailto:${contact.email}`}>{contact.email}</Link>}
                            <MarkdownTw>{contact.body}</MarkdownTw>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

function groupBrokerContacts(contacts: RegionContactModelPerson[]) {
    if (!contacts) {
        return [];
    }

    const groupedContacts = contacts.reduce((acc, obj) => {
        const { email, regionName } = obj;
        if (!acc[email]) {
            acc[email] = { ...obj, regionName: regionName };
        } else {
            acc[email].regionName = `${acc[email].regionName}, ${regionName}`;
        }
        return acc;
    }, {});

    const vals = Object.values(groupedContacts).map((item) => item);
    return vals as RegionContactModelPerson[];
}

const convertToRegionContactModelPerson = (items: ContactItemModel[]) =>
    items.map(
        (item) =>
            ({
                name: item.title,
                regionName: item.tagline,
                img: item.image?.filename,
                body: item.body,
            }) as RegionContactModelPerson,
    );
