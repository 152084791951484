"use client";

import {
    AccordionitemComponent as AccordionitemModel,
    AccordionlistComponent as AccordionlistModel,
} from "movestic-blocks/components/__generated__/graphql";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "movestic-core/tailwind/Accordion";

export const AccordionList = (props: AccordionlistModel) => {
    return (
        <div>
            <Accordion type="multiple">
                {props.accordionItems?.map((item: AccordionitemModel, index: number) => (
                    <AccordionItem
                        value={`item_${index}`}
                        key={`item_${index}`}
                        className="flex flex-col mb-6 border-none rounded-lg shadow last:mb-0 text-lightBlack"
                    >
                        <AccordionTrigger className="cursor-pointer p-6 font-bold text-left bg-white data-[state=closed]:rounded-lg data-[state=open]:bg-brightGray data-[state=open]:rounded-t-lg mb-0">
                            {props.Numbred ? <span className="font-bold text-cherryRed">{index + 1}. </span> : ""}
                            {item.title}
                        </AccordionTrigger>
                        <AccordionContent className="p-6 bg-white rounded-b-lg">
                            <RichtextContent richContent={item.text} />
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};
