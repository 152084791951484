"use client";
import {
    ListComponent as ListModel,
} from "movestic-blocks/components/__generated__/graphql";
import { ReactNode } from "react";

export const ListBlock = ({ listIcon, listItems }: ListModel) => {
    let listItemStyle = "";
    switch (listIcon) {
        case "blackBullet":
            listItemStyle = "list-none pl-8 pb-2 bg-[url('/static/images/icon-bullet-black.svg')] bg-no-repeat";
            break;
        case "coloredCheck":
            listItemStyle = "list-none pl-8 pb-2 bg-[url('/static/images/icon-check.svg')] bg-no-repeat ";
            break;
        default:
            listItemStyle = "list-none pl-8 pb-2 bg-[url('/static/images/icon-bullet.svg')] bg-no-repeat";
            break;
    }

    return (
        <ul>
            {listItems?.map((item, index) => {
                return (
                    <li className={listItemStyle} key={`li_${item.text}_${index}`}>
                        {item.text}
                    </li>
                );
            })}
        </ul>
    );
};

export const OrderedListBlock = ({ children }: { children: ReactNode }) => {
    return (
        <ol className="[&_li]:mb-2 ml-6 list-decimal marker:text-cherryDark marker:font-bold marker:mr-8">
            {children}
        </ol>
    );
};
