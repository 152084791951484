"use client";
import { ExpandablebuttonComponent as ExpandableButtonModel } from "movestic-blocks/components/__generated__/graphql";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "movestic-core/tailwind/Accordion";

export const ExpandableButton = (props: ExpandableButtonModel) => {
    return (
        <Accordion type="single" collapsible>
            <AccordionItem value="onlyitem" className="border-none">
                <AccordionTrigger className="justify-start gap-2 font-bold text-left text-cherryDark">
                    {props.title}
                </AccordionTrigger>
                <AccordionContent>
                    <RichtextContent richContent={props.contentArea} />
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};
