import { ShortcutlistComponent as ShortcutListModel } from "movestic-blocks/components/__generated__/graphql";
import { H4, TextSmall } from "movestic-core/tailwind/Texts";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import { ShortcutModel } from "movestic-blocks/tailwind/blocks/ShortcutList";
import Link from "next/link";

export const ShortcutListSimple = ({ props }: { props: ShortcutListModel }) => {
    const shortcutThreshold = parseInt(props?.columns || "2");
    const amountOfShortcuts = props.shortcuts?.length;
    let sizeClass = `lg:grid-cols-${shortcutThreshold}`;

    if (amountOfShortcuts < shortcutThreshold) {
        sizeClass =
            shortcutThreshold === 4 && amountOfShortcuts === 3
                ? "lg:w-3/4 lg:grid-cols-3"
                : shortcutThreshold === 4 && amountOfShortcuts === 2
                  ? "lg:w-2/4 lg:grid-cols-2"
                  : shortcutThreshold === 4 && amountOfShortcuts === 1
                    ? "lg:w-1/4 lg:grid-cols-1"
                    : shortcutThreshold === 3 && amountOfShortcuts === 2
                      ? "lg:w-2/3 lg:grid-cols-2"
                      : shortcutThreshold === 3 && amountOfShortcuts === 1
                        ? "lg:w-1/3 lg:grid-cols-1"
                        : shortcutThreshold === 2 && amountOfShortcuts === 1
                          ? "lg:w-1/2 lg:grid-cols-1"
                          : (sizeClass = `lg:grid-cols-${shortcutThreshold}`);
    }

    return (
        <div>
            {props.heading && <H4>{props.heading}</H4>}
            <div
                className={`grid grid-cols-2 w-full text-lightBlack ${sizeClass} shadow bg-white rounded-lg overflow-hidden`}
            >
                {props.shortcuts?.map((item, index) => {
                    const lnk = getLink(item.link);

                    return (
                        <ShortcutSimple
                            linkUrl={lnk.linkUrl}
                            linkTarget={lnk.linkTarget}
                            title={item.title}
                            icon={item.icon}
                            key={index}
                            cols={shortcutThreshold}
                            index={index}
                        />
                    );
                })}
            </div>
        </div>
    );
};

function ShortcutSimple(shortcut: ShortcutModel) {
    const borderX = (shortcut.index + 1) % +shortcut.cols ? "md:border-r" : "md:border-r-0";
    const borderY = shortcut.index + 1 > +shortcut.cols ? "md:border-t" : "md:border-t-0";

    return (
        <Link
            href={shortcut.linkUrl}
            target={shortcut.linkTarget}
            className={`flex flex-col p-6 justfy-center text-center items-center border-lavenderCloud no-underline flex-1 hover:bg-brightGray border-r border-t ${borderX} ${borderY}`}
        >
            {shortcut.icon?.filename && (
                <img
                    src={shortcut.icon?.filename}
                    style={{ width: "40px", margin: "0.25rem" }}
                    alt={shortcut.icon?.alt ?? "Shortcut icon"}
                />
            )}
            <TextSmall>{shortcut.title}</TextSmall>
        </Link>
    );
}
