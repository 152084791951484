"use client";

import * as React from "react";
import { Asset, ImageComponent as ImageProps } from "movestic-blocks/components/__generated__/graphql";
import { TextSmall } from "movestic-core/tailwind/Texts";
import { MarkdownTw } from "movestic-core/tailwind/Markdown";
import Link from "next/link";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";

const getImageDimensions = (image: Asset) => {
    const url = image.filename;
    const dimensions = {
        width: url.split("/")[5].split("x")[0],
        height: url.split("/")[5].split("x")[1],
    };

    const maxDimension = 1000;
    const maxCurrentDimension = Math.max(+dimensions.width, +dimensions.height);

    if (maxCurrentDimension <= maxDimension) {
        return dimensions;
    }

    const scaleFactor = maxDimension / maxCurrentDimension;

    return {
        width: String(Math.round(+dimensions.width * scaleFactor)),
        height: String(Math.round(+dimensions.height * scaleFactor)),
    };
};

interface ExtendedImageProps extends ImageProps {
    noBorderRadius?: boolean;
}

export const ImageBlock: React.FunctionComponent<ExtendedImageProps> = ({
    image,
    appearance,
    caption,
    video,
    link,
    noBorderRadius,
}) => {
    if (!image.filename) {
        return null;
    }

    const type: "video" | "image" | "none" = video?.filename ? "video" : image.filename ? "image" : "none";
    const focal = image.focus ? `filters:focal(${image.focus})` : "smart";

    let format = "";
    let formatx2 = "";
    let width = "";
    let height = "";
    let className = "";
    const dimensions = getImageDimensions(image);

    switch (appearance) {
        case "552x314":
            format = `/m/552x314/${focal}`;
            formatx2 = `/m/1104x628/${focal}`;
            width = "552";
            height = "314";
            className = noBorderRadius ? "" : "rounded-lg";
            break;
        case "icon":
        case "noCrop":
            format = `/m/${dimensions.width}x${dimensions.height}`;
            formatx2 = `/m/${2 * +dimensions.width}x${2 * +dimensions.height}`;
            width = dimensions.width;
            height = dimensions.height;
            break;
        default:
            break;
    }

    const lnk = getLink(link);
    const Elem = lnk?.linkUrl !== "" ? Link : "div";

    return (
        <Elem href={lnk.linkUrl ? lnk.linkUrl : null} target={lnk.linkTarget ? lnk.linkTarget : null}>
            <figure className={`flex ${appearance === "icon" ? "flex-row items-center" : " flex-col"}`}>
                <picture className={` ${appearance === "icon" ? "shrink-0 mr-2" : ""}`}>
                    {type === "video" && (
                        <video
                            playsInline
                            autoPlay
                            muted
                            loop
                            width={552}
                            height={314}
                            preload="none"
                            aria-label="Video player"
                            onContextMenu={(e) => e.preventDefault()}
                            className={`rounded-lg z-10`}
                        >
                            <source src={video.filename} />
                            {image.filename && (
                                <img
                                    src={`${image?.filename}${format}`}
                                    alt={image?.alt}
                                    width={552}
                                    height={314}
                                    className={`rounded-lg`}
                                    fetchPriority="high"
                                />
                            )}
                        </video>
                    )}
                    {type === "image" && (
                        <img
                            src={`${image?.filename}${format}`}
                            srcSet={`${image?.filename}${formatx2} 2x`}
                            alt={image?.alt}
                            width={`${+width}`} // Weird syntax? App somehow needs this?!?
                            height={`${+height}`}
                            className={className}
                        />
                    )}
                </picture>

                {caption && (
                    <figcaption className={`${appearance !== "icon" ? "mt-3" : ""}`}>
                        {appearance === "icon" ? (
                            <MarkdownTw>{caption}</MarkdownTw>
                        ) : (
                            <TextSmall>
                                <MarkdownTw>{caption}</MarkdownTw>
                            </TextSmall>
                        )}
                    </figcaption>
                )}
            </figure>
        </Elem>
    );
};
