import { rgba } from "polished";
import { css } from "styled-components";

/**
 *
 * ANY CHANGED TO THIS FILE NEEDS TO BE CHANGED IN THEME.SCSS ASWELL
 *
 */

const sizes = {
    xs: "0.25rem", // 4px.
    s: "0.5rem", // 8px
    default: "1rem", // 16px
    l: "1.5rem", // 24px
    xl: "2rem", // 32px
    xxl: "3rem", // 48px
    xxxl: "4rem", // 64px
};

const _breakpoints = {
    small: 320,
    medium: 720,
    large: 1024,
    xlarge: 1200,
};

const breakpoints = {
    xsmall: 0,
    small: `${_breakpoints.small}px`,
    medium: `${_breakpoints.medium}px`,
    large: `${_breakpoints.large}px`,
    xlarge: `${_breakpoints.xlarge}px`,
};

const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
const customMatchMedia = (minWidth: number) => `(min-width: ${minWidth}px)`;
const media = {
    custom: customMediaQuery,
    phone: customMediaQuery(_breakpoints.small),
    tablet: customMediaQuery(_breakpoints.medium),
    desktop: customMediaQuery(_breakpoints.large),
};

const matchMedia = {
    custom: customMatchMedia,
    phone: customMatchMedia(_breakpoints.small),
    tablet: customMatchMedia(_breakpoints.medium),
    desktop: customMatchMedia(_breakpoints.large),
};

const containerSizes = {
    small: "320px",
    medium: "720px",
    large: "1024px",
    xlarge: "1200px",
};

const colors = {
    // Primary colors
    cherryRed: "#D5004B",
    cherryShadow: "#B05A79",
    cherryBloom: "#DD7F9B",
    cherryPetal: "#F2D2D9",
    cherryMist: "#F9EDF0",
    cherryDark: "#AD003D",

    lavender: "#7A7EA4",
    lavenderShadow: "#66698C",
    lavenderBloom: "#9397C2",
    lavenderBud: "#AFB2CE",
    lavenderPetal: "#CECEE0",
    lavenderMist: "#E4E4EF",
    lavenderCloud: "#F1F1F6",

    lightBlack: "#3D3D3D",
    white: "#ffffff",
    brightGray: "#f8f8f8",
    lightGray: "#DDDDDD",
    ashGray: "#F2F2F2",
    gray: "#959595",
    disabledLightGray: `${rgba(210, 210, 210, 0.2)}`,

    dandelion: "#FBBA00",
    dandelionDark: "#F3C951",
    dandelionMist: "#FFE7A4",
    dandelionLight: "#FFF7DE",

    summerGrass: "#94B654",
    summerGrassDark: "#9EB573",
    summerGrassLight: "#D5E5B6",

    graniteDark: "#6D6662",
    graniteLight: "#BAACAC",

    darkBlue: "#012E55",
    pinkRed: "#dc3838",

    // Secondary colors
    blue: "#8495A6",
    lightBlue: "#d9dfe5",
    yellow: "#fdf8de",
    fundYellow: "#F5D328",
    pink: "#ffeeee",

    // Grays
    darkGray: "#4a4a4a",

    // Hover
    mediumBlue: "#1a4266",
    lightOrange: "#ef8d33",
    lightCherry: "#E66693",

    green: "#4DA800",
    lightPinkRed: "#ffeeee",

    transparent: "transparent",

    transparentLavender: `${rgba("#7A7EA4", 0.1)}`,
};

type StyledProps = {};

const gradients: {
    darkBlue: ReturnType<typeof css<StyledProps>>;
    cherryDandelion: ReturnType<typeof css<StyledProps>>;
    pinkLightPinkRed: ReturnType<typeof css<StyledProps>>;
    darkBlueBlue: ReturnType<typeof css<StyledProps>>;
    darkBluePinkRed: ReturnType<typeof css<StyledProps>>;
} = {
    darkBlue: css`
        background-image: linear-gradient(to top right, #00172a, ${colors.darkBlue});
    `,
    cherryDandelion: css`
        background-image: linear-gradient(to top right, ${colors.cherryRed}, ${colors.dandelionDark});
    `,
    pinkLightPinkRed: css`
        background-image: linear-gradient(to top right, ${colors.pinkRed}, ${colors.lightPinkRed});
    `,
    darkBlueBlue: css`
        background-image: linear-gradient(to top right, ${colors.darkBlue}, ${colors.blue});
    `,
    darkBluePinkRed: css`
        background-image: linear-gradient(to top right, ${colors.darkBlue}, ${colors.pinkRed});
    `,
};

const transparentGradients = {
    darkBlue: `linear-gradient(
        to top right,
        ${rgba("#00172A", 0.9)},
        ${rgba(colors.darkBlue, 0.5)}
    )`,
    lightBlackGranit: `linear-gradient(
        to top right,
    ${rgba(colors.lightBlack, 1)},
    ${rgba(colors.lightGray, 0)}
    )`,
};

const fontFamilies = {
    proximaNova: '"proxima-nova", sans-serif',
    neutraface: '"neutraface"',
};

const fontSize = {
    buttonTextSmall: 0.875,
    buttonText: 1,
    textTiny: 0.75,
    textMiniCapital: 0.75,
    textMini: 0.75,
    textSmall: 0.875,
    text: 1,
    textLarge: 1.125,
    intro: 1.375,
    menuText: 1,
    h4: 1.25,
    h3: 1.5,
    h2: 1.875,
    h1: 2.25,
    hero: 4,
    heroPhone: 2.75,
};

const fonts: {
    hero: ReturnType<typeof css<StyledProps>>;
    h1: ReturnType<typeof css<StyledProps>>;
    h2: ReturnType<typeof css<StyledProps>>;
    h3: ReturnType<typeof css<StyledProps>>;
    h4: ReturnType<typeof css<StyledProps>>;
    menuText: ReturnType<typeof css<StyledProps>>;
    intro: ReturnType<typeof css<StyledProps>>;
    textLarge: ReturnType<typeof css<StyledProps>>;
    text: ReturnType<typeof css<StyledProps>>;
    textSmall: ReturnType<typeof css<StyledProps>>;
    textMini: ReturnType<typeof css<StyledProps>>;
    textMiniCapital: ReturnType<typeof css<StyledProps>>;
    textTiny: ReturnType<typeof css<StyledProps>>;
    buttonText: ReturnType<typeof css<StyledProps>>;
    buttonTextSmall: ReturnType<typeof css<StyledProps>>;
} = {
    hero: css`
        font-weight: 700;
        font-family: ${fontFamilies.neutraface};
        line-height: 48px;
        font-size: ${fontSize.heroPhone}rem;

        @media screen and (min-width: ${breakpoints.medium}) {
            line-height: 67px;
            font-size: ${fontSize.hero}rem;
        }
    `,
    h1: css`
        line-height: 44px;
        font-family: ${fontFamilies.neutraface};
        font-size: ${fontSize.h1}rem;
        font-weight: 700;
    `,
    h2: css`
        line-height: 36px;
        font-family: ${fontFamilies.neutraface};
        font-size: ${fontSize.h2}rem;
        font-weight: 700;
    `,
    h3: css`
        line-height: 29px;
        font-family: ${fontFamilies.neutraface};
        font-size: ${fontSize.h3}rem;
        font-weight: 700;
    `,
    h4: css`
        line-height: 24px;
        font-family: ${fontFamilies.neutraface};
        font-size: ${fontSize.h4}rem;
        font-weight: 700;
    `,
    menuText: css`
        line-height: 19px;
        font-family: ${fontFamilies.neutraface};
        font-size: ${fontSize.menuText}rem;
        font-weight: 700;
    `,
    intro: css`
        line-height: 27px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.intro}rem;
        font-weight: 400;
    `,
    textLarge: css`
        line-height: 26px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.textLarge}rem;
        font-weight: 400;
    `,
    text: css`
        line-height: 22px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.text}rem;
        font-weight: 400;
    `,
    textSmall: css`
        line-height: 19px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.textSmall}rem;
        font-weight: 400;
    `,
    textMini: css`
        line-height: 15px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.textMini}rem;
        font-weight: 400;
    `,
    textMiniCapital: css`
        line-height: 15px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.textMiniCapital}rem;
        text-transform: uppercase;
        font-weight: 700;
    `,
    textTiny: css`
        line-height: 14px;
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.textTiny}rem;
        text-transform: uppercase;
    `,
    buttonText: css`
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.buttonText}rem;
        font-weight: 700;
    `,
    buttonTextSmall: css`
        font-family: ${fontFamilies.proximaNova};
        font-size: ${fontSize.buttonTextSmall}rem;
        font-weight: 600;
    `,
};

export type BackgroundTheme = "default" | "pinkRed" | "lavender" | "lightCherry" | "cherryShadow";

const backgroundThemes: { [key in BackgroundTheme]: ReturnType<typeof css<StyledProps>> } = {
    default: css`
        background-color: ${(props) => props.theme.color.white};
        color: ${(props) => props.theme.color.lightBlack};

        hr {
            border-top-color: ${(props) => props.theme.color.cherryRed};
        }
    `,

    pinkRed: css`
        background-color: ${(props) => props.theme.color.pinkRed};
        color: ${(props) => props.theme.color.white};

        hr {
            border-top-color: ${(props) => props.theme.color.white};
        }
    `,

    lavender: css`
        background-color: ${(props) => props.theme.color.lavender};
        color: ${(props) => props.theme.color.white};

        hr {
            border-top-color: ${(props) => props.theme.color.white};
        }
    `,
    lightCherry: css`
        background-color: ${(props) => props.theme.color.lightCherry};
        color: ${(props) => props.theme.color.white};

        hr {
            border-top-color: ${(props) => props.theme.color.white};
        }
    `,
    cherryShadow: css`
        background-color: ${(props) => props.theme.color.cherryShadow};
        color: ${(props) => props.theme.color.white};

        hr {
            border-top-color: ${(props) => props.theme.color.white};
        }
    `,
};

const maiaTheme = {
    maxWidthContent: "700px",
    titlebarHeight: "57px",
    progressbarHeight: "89px",
};

const mainTheme = {
    maia: {
        ...maiaTheme,
    },
    margin: {
        ...sizes,
    },
    padding: {
        ...sizes,
    },
    color: {
        ...colors,
    },
    gradients: {
        ...gradients,
    },
    transparentGradients: {
        ...transparentGradients,
    },
    sizes: {
        ...sizes,
    },
    fonts: {
        ...fonts,
    },
    fontFamilies: {
        ...fontFamilies,
    },
    breakpoints: {
        ...breakpoints,
    },
    containerSizes: {
        ...containerSizes,
    },
    media,
    matchMedia,
    themeColors: {
        border: `1px solid ${colors.lavenderPetal}`,
        background: colors.transparent,
        color: colors.lightBlack,
        hr: {
            borderTop: `1px solid ${colors.cherryRed}`,
        },
        backgroundImage: "none",
        backgroundSize: "auto",
        borderColor: colors.lavenderPetal,
        backgroundColor: colors.transparent,
        buttonBorderColor: colors.cherryRed,
        buttonColor: colors.cherryRed,
        buttonHoverColor: colors.cherryBloom,
        buttonHoverBorderColor: colors.cherryBloom,
        buttonTextColor: colors.white,
        buttonTextHoverColor: colors.white,
        horizontalRuleColor: colors.cherryRed,
        primary: colors.cherryRed,
        secondary: colors.lightCherry,
        secondaryLight: colors.lightCherry,
        textColor: colors.lightBlack,
    },
    backgroundThemes,
};

const customerTheme: typeof mainTheme = {
    ...mainTheme,
    themeColors: {
        ...mainTheme.themeColors,
        primary: mainTheme.color.cherryRed,
        secondary: mainTheme.color.lavender,
        secondaryLight: mainTheme.color.lightCherry,
    },
};

export { customerTheme, fontSize };
export default mainTheme;
