export enum GtmEventType {
    Interaction = "Interaction",
    CompanyPensionForm = "CompanyPensionForm",
    //DigitalLife = "DigitalLife",
}

export type WizardObject = {
    formName: string;
    stepName: string;
};

export type BannerObject = {
    page?: string;
    position?: string;
    bannerId?: string;
    action?: "View" | "Click";
};

export enum LoginObjectType {
    BankidGeneral = "Method: Bankid",
    BankidOnThisDevice = "Method: Bankid on This Device",
    BankidOnOtherDevice = "Method: Bankid on Other Device",
    UserName = "Method: Username/password",
}

export type LoginObject = {
    type: string;
    isSuccess: boolean;
};

export type GenericObject = {
    textValue?: string;
};

export type FundChangeObject = {
    type: string;
    isSuccess: boolean;
};

export type FundlistObject = {
    type: string;
    name: string;
};

export enum EventType {
    Form = "Form",
    Banner = "Banner",
    ContactForm = "ContactForm",
    CookieConsent = "CookieConsent",
    FileDownload = "FileDownload",
    Login = "Login",
    Funds = "Funds",
    MoveIn = "MoveIn",
    SalaryTax = "SalaryTax",
}

export enum WizardKey {
    GroupAccident = "Group Accident",
    GroupDisabledAccident = "Group Disabled Accident",
    Outpayment = "Outpayment",
    PartnerNewAgreement = "PartnerNewAgreement",
    AmlQuestions = "AmlQuestions",
    AmlCompany = "AmlCompany",
    CompanyAmlInsuranceQuestions = "CompanyAmlInsuranceQuestions",
    ClaimsAccidentAdult = "ClaimsAccidentAdult",
    ClaimsSickleave = "ClaimsSickleave",
    ClaimsAccidentChild = "ClaimsAccidentChild",
    ClaimsDeath = "ClaimsDeath",
    ClaimsDisabledAdult = "ClaimsDisabledAdult",
    ClaimsDisabledChild = "ClaimsDisabledChild",
    CapitalInsurance = "CapitalInsurance",
    DigitalLife = "DigitalLife",
    PartnerMoveIn = "PartnerMoveIn",
    Maia = "Maia",
    FundChange = "FundChange",
    PlanEarly = "PlanEarly",
    SelectOutpaymentPlanEarly = "SelectOutpaymentPlanEarly",
}
