import { CardComponent as CardProps } from "movestic-blocks/components/__generated__/graphql";
import { CardlistComponent as CardListProps } from "movestic-blocks/components/__generated__/graphql";
import { H4 } from "movestic-core/tailwind/Texts";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import { PrimaryLink, PrimaryLinkButton, SecondaryLinkButton } from "movestic-core/tailwind/Buttons";
import { ArrowRight } from "movestic-core/tailwind/icons/ArrowRight";
import { MarkdownTw } from "movestic-core/tailwind/Markdown";

export const CardBlock: React.FunctionComponent<CardProps & { cropImage?: boolean }> = ({
    text,
    image,
    link,
    linktext,
    heading,
    linkVariant = "link",
    linkPosition,
    cropImage = true,
    backgroundColor,
}) => {
    const { linkUrl, linkTarget } = getLink(link);

    const linkTypes = {
        primaryButton: PrimaryLinkButton,
        secondaryButton: SecondaryLinkButton,
        link: PrimaryLink,
    };
    const ButtonLink = linkTypes[linkVariant] || PrimaryLink;
    const hasImageAndHeading = image?.filename && heading;

    let background: string;

    switch (backgroundColor) {
        case "gray":
            background = "bg-brightGray";
            break;
        case "cherryMist":
            background = "bg-cherryMist";
            break;
        default:
            background = "bg-white shadow";
            break;
    }

    return (
        <div className={`flex flex-col p-6 text-lightBlack ${background} rounded-lg`}>
            {heading && <H4 className={`pb-2 ${hasImageAndHeading ? "mb-6" : "mb-0"}`}>{heading}</H4>}
            <div className={`flex flex-row justify-start gap-6`}>
                {image?.filename && (
                    <div>
                        <img
                            className="object-cover rounded-full"
                            src={`${image?.filename}${cropImage ? "/m/200x200/smart" : ""}`}
                            alt={image.alt}
                            width={100}
                            height={100}
                        />
                    </div>
                )}
                <div className={`flex flex-col grow justify-center`}>
                    <MarkdownTw>{text}</MarkdownTw>
                </div>
            </div>
            {linkUrl && (
                <div className={`flex ${linkPosition === "left" ? "" : "justify-end"} pt-4 mt-auto`}>
                    <ButtonLink href={linkUrl} target={linkTarget} className="flex flex-row gap-2">
                        {linktext}
                        {linkVariant === "link" && <ArrowRight />}
                    </ButtonLink>
                </div>
            )}
        </div>
    );
};

export const CardBlockList = ({ cardItems, columns }: CardListProps) => {
    let gridColumns: string;

    switch (columns) {
        case "3":
            gridColumns = "lg:grid-cols-3";
            break;
        case "4":
            gridColumns = "lg:grid-cols-4";
            break;
        default:
            gridColumns = "lg:grid-cols-2";
            break;
    }
    return (
        <div className={`grid w-full gap-6 ${gridColumns}`}>
            {cardItems?.map((card: CardProps, index: number) => <CardBlock key={index} {...card} />)}
        </div>
    );
};
