import * as React from "react";
import { TableComponent as TableModel } from "movestic-blocks/components/__generated__/graphql";
type TempTableModel = {
    thead: { value: string }[];
    tbody: { body: { value: string }[] }[];
};

export const BasicTable: React.FunctionComponent<TableModel> = ({ table: ta, options }) => {
    const table = ta as unknown as TempTableModel;

    const isZebra = options?.includes("zebraMode");
    const hasHeader = !options?.includes("skipHeader");
    const hasStrongFirstColumn = options?.includes("strongFirstColumn");
    const hasCherryFirstColumn = options?.includes("cherryFirstColumn");

    return (
        <div className="w-[calc(100vw-2rem)] md:w-auto overflow-x-auto">
            <table className="w-full ">
                {hasHeader && (
                    <thead>
                        <tr className="bg-cherryMist">
                            {table.thead?.map((item, index) => (
                                <th
                                    className="!px-4 !py-3 text-base font-bold text-left text-lightBlack normal-case border-none"
                                    key={index}
                                >
                                    {item.value}
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {table.tbody?.map((row, i) => (
                        <tr
                            key={"tr-" + i}
                            className={`border-b border-b-brightGray text-lightBlack ${isZebra ? "odd:bg-brightGray even:bg-white" : "bg-white"}`}
                        >
                            {row.body.map((td, index) => {
                                return (
                                    <td
                                        className={`px-4 py-2 align-top text-base ${index === 0 && hasStrongFirstColumn ? "font-bold" : "font-normal"} ${index === 0 && hasCherryFirstColumn ? "bg-cherryMist" : ""}`}
                                        key={index}
                                        dangerouslySetInnerHTML={{ __html: td.value?.replace(/(\n)+/g, "<br />") }}
                                    />
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
