"use client";
import MenuItemLink from "movestic-core/types/MenuItemLink";
import Link from "next/link";
import { getStructuredDataBreadcrumbs } from "movestic-blocks/utils/structuredData";
import Script from "next/script";
import React from "react";

export const Breadcrumb: React.FunctionComponent<{
    navigation: MenuItemLink[];
    className?: string;
}> = ({ navigation, className }) => {
    const breadcrumbStructuredData = getStructuredDataBreadcrumbs(navigation);

    return (
        <div
            className={`flex gap-2 ${className} overflow-scroll md:overflow-auto text-small md:text-base max-w-[calc(100vw-2rem)]`}
        >
            <Script
                type="application/ld+json"
                id="structuredBreadcrumbList"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(breadcrumbStructuredData),
                }}
            />
            {navigation?.map((nav, index) => {
                const noLink = index === navigation.length - 1 || !nav.url;
                const showSeperator = index !== navigation.length - 1;
                return (
                    <React.Fragment key={nav.text}>
                        {noLink ? (
                            <div className="">
                                <div className="whitespace-nowrap">{nav.text}</div>
                            </div>
                        ) : (
                            <div>
                                <Link
                                    href={nav.url}
                                    key={index}
                                    className="text-cherryDark hover:text-cherryRed whitespace-nowrap"
                                >
                                    {nav.text}
                                </Link>
                            </div>
                        )}
                        {showSeperator && <div>›</div>}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
