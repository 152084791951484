import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { GlobalblockComponent as GlobalBlockModel } from "movestic-blocks/components/__generated__/graphql";

export const GlobalBlock = (props: GlobalBlockModel) => {
    // props.link is an array, but always 1 item
    if (props.link && props.link.length && props.link[0].content.richContent) {
        return <RichtextContent richContent={props.link[0].content.richContent} />;
    }

    return null;
};
