import { NewsListQueryQuery } from "movestic-blocks/components/__generated__/graphql";
import MenuItemLink from "movestic-core/types/MenuItemLink";
import { isClient } from "movestic-core/utils/environment";

const webbUrl = {
    public: "https://www.movestic.se",
    funds: "https://www.movesticfonder.se",
    partner: "https://www.movestic.se/partner",
};

export const structuredDataAboutUs = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    name: "Om Movestic - Personligt sparande och försäkring",
    url: "https://www.movestic.se/om-movestic",
    description:
        "Personligt anpassat privat sparande, försäkringsskydd och tjänstepension för privatpersoner och företagare.",
    isPartOf: { "@type": "WebSite", name: "Movestic", url: "https://www.movestic.se" },
    mainEntity: {
        "@type": "Organization",
        name: "Movestic",
        url: "https://www.movestic.se",
        logo: "https://www.movestic.se/_next/image?url=%2Fstatic%2Fimages%2Fmovestic-logo.svg&w=256&q=75",
        sameAs: [
            "https://www.facebook.com/movestic",
            "https://www.instagram.com/movestic",
            "https://www.linkedin.com/company/movestic",
            "https://twitter.com/movestic",
            "https://sv.wikipedia.org/wiki/Movestic_Liv_%26_Pension",
        ],
        contactPoint: [
            {
                "@type": "ContactPoint",
                telephone: "+46-(0)8-120 393 20",
                contactType: "customer service",
                areaServed: "SE",
                availableLanguage: ["Swedish", "English"],
            },
        ],
        address: {
            "@type": "PostalAddress",
            streetAddress: "Box 7853",
            addressLocality: "Stockholm",
            postalCode: "103 99",
            addressCountry: "SE",
        },
        foundingDate: "2000-01-01",
        employee: { "@type": "Person", name: "VD: Sara Lindberg", jobTitle: "CEO" },
    },
};

export const getStructuredDataPage = (page, webb, slugs) => {
    const aboutUsSlugs = ["om-movestic", "om-oss", "om-foretaget"];
    const startPageSlugs = [undefined, ""];

    if (aboutUsSlugs.includes(slugs?.join("/"))) {
        return structuredDataAboutUs;
    }

    if (startPageSlugs.includes(slugs?.join(""))) {
        return getStructuredDataStartPage(webb);
    }

    if (!page.metaTitle || webbUrl[webb] === undefined) {
        return null;
    }

    return {
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: page?.metaTitle,
        url: `${webbUrl[webb]}/${slugs.join("/")}`,
        description: page?.metaDescription,
        inLanguage: "sv",
        isPartOf: { "@type": "WebSite", name: "Movestic", url: webbUrl[webb] },
    };
};

export const getStructuredDataArticle = (content, allAuthors) => {
    return {
        "@context": "http://schema.org",
        "@type": "Article",
        headline: content.heading,
        image: content.image?.filename ? [content.image?.filename] : undefined,
        datePublished: new Date(content.date),
        dateModified: new Date(content.date),
        keywords: [content.category],
        author: allAuthors?.map((item) => ({
            "@type": item?.name === "Movestic" ? "Organization" : "Person",
            name: item?.name,
        })),
    };
};

export const getStructuredDataBreadcrumbs = (navigation: MenuItemLink[]) => {
    const loc = isClient() ? document.location : undefined;

    if (!loc) {
        return null;
    }

    return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: navigation.map((nav, index) => ({
            "@type": "ListItem",
            position: index + 1,
            name: nav.text,
            item: loc.origin + (nav.url?.pathname ?? ""),
        })),
    };
};

export const getStructuredNewsListData = (news: NewsListQueryQuery, webb) => {
    if (webbUrl[webb] === undefined) {
        return null;
    }

    const firstNewsItems = news?.NewsitemItems?.items?.slice(0, 8);

    return {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        name: "Senaste nyheter",
        url: `${webbUrl[webb]}/nyheter`,
        description:
            "Håll dig uppdaterad med de senaste fondnyheterna, marknadsinsikterna och viktiga förändringar inom investeringar och sparande.",
        mainEntity: [
            ...firstNewsItems.map((newsItem) => {
                return {
                    "@type": "Article",
                    headline: newsItem?.content?.heading,
                    datePublished: newsItem?.content?.date,
                    url: `${webbUrl[webb]}/${newsItem?.full_slug}`,
                    description: newsItem?.content?.intro,
                    image: newsItem?.content?.image?.filename ? newsItem?.content?.image?.filename : undefined,
                    keywords: [newsItem?.content?.category],
                };
            }),
        ],
    };
};

export const getStructuredDataStartPage = (webb: string) => {
    if (webb !== "public") {
        return null;
    }

    return {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebSite",
                name: "Movestic",
                url: "https://www.movestic.se",
                potentialAction: {
                    "@type": "SearchAction",
                    target: "https://www.movestic.se/sok?q={search_term_string}",
                    "query-input": "required name=search_term_string",
                },
            },
            {
                "@type": "Organization",
                name: "Movestic",
                url: "https://www.movestic.se",
                logo: "https://www.movestic.se/_next/image?url=%2Fstatic%2Fimages%2Fmovestic-logo.svg&w=256&q=75",
                contactPoint: {
                    "@type": "ContactPoint",
                    telephone: "+46-(0)8-120 393 20",
                    contactType: "customer service",
                    areaServed: "SE",
                    availableLanguage: "Swedish",
                },
            },
        ],
    };
};

export const getStructuredDataFaqPage = () => {
    return {};
    //TODO: Somewhat tricky. Example
    // {
    //     "@context": "https://schema.org",
    //     "@graph": [
    //       {
    //         "@type": "WebPage",
    //         "name": "Livförsäkring - Trygghet för dina närmaste",
    //         "url": "https://www.movestic.se/forsakringar/livforsakring",
    //         "description": "En livförsäkring ger ekonomisk trygghet till efterlevande vid dödsfall."
    //       },
    //       {
    //         "@type": "FAQPage",
    //         "mainEntity": [
    //           {
    //             "@type": "Question",
    //             "name": "När gäller en livförsäkring?",
    //             "acceptedAnswer": {
    //               "@type": "Answer",
    //               "text": "Livförsäkringen gäller dygnet runt över hela världen."
    //             }
    //           },
    //           {
    //             "@type": "Question",
    //             "name": "När gäller inte en livförsäkring?",
    //             "acceptedAnswer": {
    //               "@type": "Answer",
    //               "text": "Försäkringen gäller inte vid dödsfall på grund av suicid under de fösta 12 månaderna efter att försäkringen tecknats. Inte heller dödsfall som sker på grund av krig, terrorism, atomkärnprocess, force majeure eller vid särskilt riskfyllda aktiviteter. <a href='https://app.movestic.se/api/document/quick/PVIL'>Du hittar mer information i försäkringens villkor.</a>"
    //             }
    //           },
    //           {
    //             "@type": "Question",
    //             "name": "Vad kostar en livförsäkring?",
    //             "acceptedAnswer": {
    //               "@type": "Answer",
    //               "text": "Kostnaden för en livförsäkring beror bland annat på hur gammal du är. <a href='https://app.movestic.se/api/document/quick/PRIS-IndLiv'>Här hittar du</a> prislistan för Movestics livförsäkring."
    //             }
    //           }
    //         ]
    //       }
    //     ]
    //   }
};
