"use client";
import {
    FaqlistComponent as FaqlistModel,
    FaqitemComponent as FaqitemModel,
} from "movestic-blocks/components/__generated__/graphql";
import { H3 } from "movestic-core/tailwind/Texts";
import { useState } from "react";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "movestic-core/tailwind/Accordion";

export const FaqList = (props: FaqlistModel) => {
    const faqItems = props.faqItems as FaqitemModel[];
    const faqTags = [...new Set<string>(props.faqItems.flatMap((p) => p.tag)?.filter((x) => x))];
    const [tag, setTag] = useState<string>(null);
    const activeClassnames = "font-bold border-2 border-cherryRed bg-cherryMist";
    const tagClassnames =
        "text-lightBlack border rounded-lg p-2 px-4 whitespace-nowrap cursor-pointer hover:bg-cherryMist";

    return (
        <>
            {props.heading && <H3>{props.heading}</H3>}
            {props.showTags && (
                <div className="flex flex-wrap justify-center gap-4">
                    <div
                        className={`${tagClassnames} ${tag === null ? activeClassnames : "border-lightGray bg-white"}`}
                        onClick={() => setTag(null)}
                    >
                        Alla
                    </div>
                    {faqTags.map((tagItem) => (
                        <div
                            key={tagItem}
                            className={`${tagClassnames} ${tagItem === tag ? activeClassnames : "border-lightGray bg-white"}`}
                            onClick={() => setTag(tagItem === tag ? null : tagItem)}
                        >
                            {tagItem}
                        </div>
                    ))}
                </div>
            )}
            <div className="w-full">
                <Accordion type="multiple">
                    {faqItems
                        ?.filter((item) => item.tag?.includes(tag) || !tag)
                        ?.map((item, index) => (
                            <AccordionItem
                                value={`item_${index}`}
                                key={`item_${index}`}
                                className="justify-start gap-2 border-b-lightGray"
                            >
                                <AccordionTrigger className="font-bold text-left">{item.question}</AccordionTrigger>
                                <AccordionContent className="pt-4 text-left">
                                    <RichtextContent richContent={item.answer2} />
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                </Accordion>
            </div>
        </>
    );
};
