import {
    WizardObject,
    BannerObject,
    GenericObject,
    LoginObject,
    FundChangeObject,
    FundlistObject,
    EventType,
    WizardKey,
    GtmEventType,
} from "movestic-core/types/gtmTypes";
import { isClient } from "movestic-core/utils/environment";

import {
    friendlyNameForDirectFlowStep,
    StepDisplayKey_NewAgreement,
} from "movestic-core/types/NewAgreement.Enumerations";

type EventObject = WizardObject | BannerObject | GenericObject | LoginObject | FundChangeObject | FundlistObject;

const trackEvent = (eventType: EventType, dataObject: EventObject, legacyDataObject?: object) => {
    pushToDatalayer({
        event: GtmEventType.Interaction,
        data: {
            eventType: eventType.toString(),
            ...dataObject,
        },
        ...legacyDataObject, // TODO: Remove legacyDataObject data when old GA is obsolete (fall 2023?)
    });
};

const pushToDatalayer = (object: any) => {
    if (isClient()) {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({ data: null });
        // @ts-ignore
        window.dataLayer.push(object);
    }
};

const trackDownload = (fileName: string) => {
    trackEvent(EventType.FileDownload, { value: fileName } as GenericObject);
};

const trackWizardStep = ({ stepKey, wizardKey }: { stepKey: string; wizardKey: WizardKey }) => {
    const wizardObject: WizardObject = {
        formName: wizardKey,
        stepName: stepKey,
    };
    trackEvent(EventType.Form, wizardObject, { category: wizardKey, action: stepKey });
};

const trackNewDirectFlowAgreement = ({
    stepKey,
    skipped,
}: {
    stepKey: StepDisplayKey_NewAgreement;
    skipped: boolean;
}) => {
    const friendlyStepName = friendlyNameForDirectFlowStep(stepKey);

    pushToDatalayer({
        event: GtmEventType.CompanyPensionForm,
        stepName: friendlyStepName,
        stepKey,
        skipped,
    });
};

const setLoggedInUser = (arcaUserId: string) => {
    pushToDatalayer({
        userId: arcaUserId,
    });
};

// export enum GtmEventType {
//     Interaction = "Interaction",
//     CompanyPensionForm = "CompanyPensionForm",
//     //DigitalLife = "DigitalLife",
// }

// export type WizardObject = {
//     formName: string;
//     stepName: string;
// };

// export type BannerObject = {
//     page?: string;
//     position?: string;
//     bannerId?: string;
//     action?: "View" | "Click";
// };

// export enum LoginObjectType {
//     BankidGeneral = "Method: Bankid",
//     BankidOnThisDevice = "Method: Bankid on This Device",
//     BankidOnOtherDevice = "Method: Bankid on Other Device",
//     UserName = "Method: Username/password",
// }

// export type LoginObject = {
//     type: string;
//     isSuccess: boolean;
// };

// export type GenericObject = {
//     textValue?: string;
// };

// export type FundChangeObject = {
//     type: string;
//     isSuccess: boolean;
// };

// export type FundlistObject = {
//     type: string;
//     name: string;
// };

// enum EventType {
//     Form = "Form",
//     Banner = "Banner",
//     ContactForm = "ContactForm",
//     CookieConsent = "CookieConsent",
//     FileDownload = "FileDownload",
//     Login = "Login",
//     Funds = "Funds",
//     MoveIn = "MoveIn",
//     SalaryTax = "SalaryTax",
// }

// enum WizardKey {
//     GroupAccident = "Group Accident",
//     GroupDisabledAccident = "Group Disabled Accident",
//     Outpayment = "Outpayment",
//     PartnerNewAgreement = "PartnerNewAgreement",
//     AmlQuestions = "AmlQuestions",
//     AmlCompany = "AmlCompany",
//     CompanyAmlInsuranceQuestions = "CompanyAmlInsuranceQuestions",
//     ClaimsAccidentAdult = "ClaimsAccidentAdult",
//     ClaimsSickleave = "ClaimsSickleave",
//     ClaimsAccidentChild = "ClaimsAccidentChild",
//     ClaimsDeath = "ClaimsDeath",
//     ClaimsDisabledAdult = "ClaimsDisabledAdult",
//     ClaimsDisabledChild = "ClaimsDisabledChild",
//     CapitalInsurance = "CapitalInsurance",
//     DigitalLife = "DigitalLife",
//     PartnerMoveIn = "PartnerMoveIn",
//     Maia = "Maia",
//     FundChange = "FundChange",
//     PlanEarly = "PlanEarly",
//     SelectOutpaymentPlanEarly = "SelectOutpaymentPlanEarly",
// }

export {
    trackEvent,
    trackDownload,
    trackWizardStep,
    trackNewDirectFlowAgreement,
    setLoggedInUser,
    EventType,
    WizardKey,
};
