"use client";

import * as React from "react";
import mainTheme from "movestic-core/main.theme";
import {
    Bar as RechartsBar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { JSX } from "react";
import { ChartDataItem } from "movestic-core/components/charts/Chart.Utilites";

interface Props {
    size: number;
    dataSet: ChartDataItem[];
    xAxisTickFormatter?: (value: any, index: number) => string;
    yAxisTickFormatter?: (value: any, index: number) => string;
    toolTipContent?: JSX.Element;
    showToolTip?: boolean;
}

export const Bar: React.FunctionComponent<Props> = ({
    size,
    dataSet,
    showToolTip = false,
    xAxisTickFormatter = (v) => String(v),
    yAxisTickFormatter = (v) => String(v),
    toolTipContent = <></>,
}) => {
    if (!dataSet || dataSet.length === 0) {
        return null;
    }

    interface Margin {
        left: number;
        bottom: number;
    }

    let ctx: CanvasRenderingContext2D;
    const measureText = (text: string) => {
        if (typeof window !== "undefined") {
            if (!ctx) {
                ctx = document.createElement("canvas").getContext("2d")!;
                ctx.font = '12px "Proxima-nova"';
            }
            return ctx.measureText(text).width;
        }
    };
    let leftMargin = 0;
    let bottomMargin = 0;

    const margin = dataSet.map((d, i) => {
        const textWidth = measureText(yAxisTickFormatter(d.value, i));
        if (textWidth > leftMargin) {
            leftMargin = textWidth;
        }
        const textHeight = measureText(xAxisTickFormatter(d.label, i));
        if (textHeight > bottomMargin) {
            bottomMargin = textHeight;
        }
        // We have pixel-perfect measurements for the width of our labels, but we also need to account for the default spacing.
        return {
            left: (leftMargin = Math.ceil(Math.max(0, leftMargin * 0.5))),
            bottom: (bottomMargin = Math.ceil(Math.max(0, bottomMargin))),
        } as Margin;
    });

    leftMargin =
        margin && margin.length > 0 && margin.reduce((prev, curr) => (prev.left > curr.left ? prev : curr)).left;

    bottomMargin =
        margin && margin.length > 0 && margin.reduce((prev, curr) => (prev.bottom > curr.bottom ? prev : curr)).bottom;

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill={mainTheme.color.gray} transform="rotate(-45)">
                    {payload.value}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={size}>
            <BarChart
                data={dataSet}
                barGap={10}
                barCategoryGap="20%"
                margin={{ left: leftMargin, bottom: bottomMargin }}
                className="text-mini"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="label"
                    tickFormatter={xAxisTickFormatter}
                    tickLine={false}
                    tickMargin={10}
                    interval="preserveStart"
                    tick={CustomizedAxisTick}
                >
                    <Label position={"bottom"} />
                </XAxis>
                <YAxis tickFormatter={yAxisTickFormatter} tickLine={false} />
                <RechartsBar dataKey={"value"} width={100} isAnimationActive={true}>
                    {dataSet.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </RechartsBar>
                {showToolTip && <Tooltip content={toolTipContent} />}
            </BarChart>
        </ResponsiveContainer>
    );
};
