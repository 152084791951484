class BaseClient {
    protected getBaseUrl = (_defaultUrl?: string, ..._args: any[]) => process.env.API_PATH;

    protected transformOptions(options: RequestInit): Promise<RequestInit> {
        options.credentials = "include";
        options.headers = { ...options.headers, pragma: "no-cache", "cache-control": "no-cache" };

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve(options);
        });
    }
}

export default BaseClient;
