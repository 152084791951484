// import { WizardKey } from "movestic-core/types/gtmTypes";

// export const NewAgreementWizardKey = WizardKey.PartnerNewAgreement;

export enum StepDisplayKey_NewAgreement {
    Initialized = "Initialized",
    CompanyLoaded = "CompanyLoaded",
    NewAgreementSubmitted = "NewAgreementSubmitted",
    BrokerSelector = "BrokerSelector",
    Offerings = "Offerings",
    Procurement = "Procurement",
    CreatePensionPlanGroup = "CreatePensionPlanGroup",
    ChooseProducts = "ChooseProducts",
    ConfigureProducts = "ConfigureProducts",
    PensionPlanGroups = "PensionPlanGroups",
    AddEmployees = "AddEmployees",
    AgreementSettings = "AgreementSettings",
    SubmitPensionPlan = "SubmitPensionPlan",
    SubmitCreatePensionPlanGroup = "SubmitCreatePensionPlanGroup",
    DirectFlowStart = "DirectFlowStart",
    DirectFlowConfigureProductOwner = "DirectFlowConfigureProductOwner",
    DirectFlowConfigureProductEmployees = "DirectFlowConfigureProductEmployees",
    FreestandingConfigureProduct = "FreestandingConfigureProduct",
    FreestandingControllingPersons = "FreestandingControllingPersons",
    FreestandingPrivateOwner = "FreestandingPrivateOwner",
    FreestandingInsured = "FreestandingInsured",
    FreestandingSubmit = "FreestandingSubmit",
}

export const friendlyNameForDirectFlowStep = (key: StepDisplayKey_NewAgreement) => {
    const keyMapping: { [key in StepDisplayKey_NewAgreement]?: string } = {
        Initialized: "Välkommen",
        CompanyLoaded: "Val av tjänstepension",
        DirectFlowConfigureProductOwner: "Dina val för företagare",
        SubmitPensionPlan: "Signera",
        AddEmployees: "Lägg till personer",
        PensionPlanGroups: "Översikt",
        AgreementSettings: "Betalningsuppgifter",
        DirectFlowConfigureProductEmployees: "Dina val för anställda",
        NewAgreementSubmitted: "Ansökan skickad",
    };

    return keyMapping[key];
};
