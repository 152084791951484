export type RegionContactModelPerson = {
    email?: string;
    name?: string;
    regionName?: string;
    phone?: string;
    img?: string;
    body?: string;
};

const imagePath = `/static/images`;

const images = {
    jensva: `${imagePath}/broker-contact-person/jenny.svanborg.webp`,
    marpek: `${imagePath}/broker-contact-person/martin.pekberg.webp`,
    sandah: `${imagePath}/broker-contact-person/sandra.dahl.webp`,
    tobann: `${imagePath}/broker-contact-person/tobias.annermalm.webp`,
    toblin: `${imagePath}/broker-contact-person/tobias.lindstrom.webp`,
    tobpry: `${imagePath}/broker-contact-person/tobias.prytz.webp`,
    joheng: `${imagePath}/broker-contact-person/johan.englund.webp`,
    johand: `${imagePath}/broker-contact-person/johan.andersson.webp`,
    jandoe: `${imagePath}/broker-contact-person/jane.doe.webp`,
    willham: `${imagePath}/broker-contact-person/william.hamzeh.webp`,
    davham: `${imagePath}/broker-contact-person/david.hamberg.webp`,
    maralv: `${imagePath}/broker-contact-person/martina.alvarsson.webp`,
};

export const BrokerContactMapping = (contactPersonEmail: string, regionName?: string): RegionContactModelPerson => {
    const email = contactPersonEmail?.toLocaleLowerCase();
    switch (email) {
        case "tobias.prytz@movestic.se":
            return {
                email: "tobias.prytz@movestic.se",
                name: "Tobias Prytz",
                phone: "072-253 02 79",
                img: images.tobpry,
                regionName,
            };
        case "sandra.dahl@movestic.se":
            return {
                email: "sandra.dahl@movestic.se",
                name: "Sandra Dahl",
                phone: "073-981 09 31",
                img: images.sandah,
                regionName,
            };
        case "martin.pekberg@movestic.se":
            return {
                email: "martin.pekberg@movestic.se",
                name: "Martin Pekberg",
                phone: "070-887 60 53",
                img: images.marpek,
                regionName,
            };
        case "tobias.annermalm@movestic.se":
            return {
                email: "tobias.annermalm@movestic.se",
                name: "Tobias Annermalm",
                phone: "073-620 28 28",
                img: images.tobann,
                regionName,
            };
        case "jenny.svanborg@movestic.se":
            return {
                email: "jenny.svanborg@movestic.se",
                name: "Jenny Svanborg",
                phone: "070-370 93 03",
                img: images.jensva,
                regionName,
            };
        case "johan.englund@movestic.se":
            return {
                email: "johan.englund@movestic.se",
                name: "Johan Englund",
                phone: "",
                img: images.joheng,
                regionName,
            };
        case "rickard.wingemo@movestic.se":
            return {
                email: "rickard.wingemo@movestic.se",
                name: "Rickard Wingemo",
                phone: "",
                img: images.jandoe,
                regionName,
            };
        case "johan.andersson@movestic.se":
            return {
                email: "johan.andersson@movestic.se",
                name: "Johan Andersson",
                phone: "",
                img: images.johand,
                regionName,
            };
        case "tobias.lindstrom@movestic.se":
            return {
                email: "tobias.lindstrom@movestic.se",
                name: "Tobias Lindström",
                phone: "073-620 28 84",
                img: images.toblin,
                regionName,
            };
        case "william.hamzeh@movestic.se":
            return {
                email: "william.hamzeh@movestic.se",
                name: "William Hamzeh",
                phone: "072-253 02 75",
                img: images.willham,
                regionName,
            };
        case "david.hamberg@movestic.se":
            return {
                email: "david.hamberg@movestic.se",
                name: "David Hamberg",
                phone: "073-620 28 42",
                img: images.davham,
                regionName,
            };
        case "martina.alvarsson@movestic.se":
            return {
                email: "martina.alvarsson@movestic.se",
                name: "Martina Alvarsson",
                phone: "073-620 28 72",
                img: images.maralv,
                regionName,
            };
        default:
            return email ? { email, regionName, name: "", phone: "" } : null;
    }
};
