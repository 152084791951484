import { ShortcutModel } from "movestic-blocks/tailwind/blocks/ShortcutList";
import { ShortcutlistComponent as ShortcutListModel } from "movestic-blocks/components/__generated__/graphql";
import { ShortcutitemComponent as ShortcutItemModel } from "movestic-blocks/components/__generated__/graphql";
import { H4 } from "movestic-core/tailwind/Texts";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import Link from "next/link";
import { getGrid } from "./ShortcutlistHigh";

export const ShortcutListLow = ({ props }: { props: ShortcutListModel }) => {
    const shortcuts = props.shortcuts as ShortcutItemModel[];
    const shortcutThreshold = parseInt(props?.columns || "4");
    const shortcutGrid = getGrid(shortcutThreshold);
    const iconPosition = props.iconPosition;

    return (
        <div>
            {props.heading && <H4>{props.heading}</H4>}
            <div className={`grid justify-center gap-6 text-left grid-cols-1 text-lightBlack ${shortcutGrid}`}>
                {shortcuts?.map((item, index) => {
                    const lnk = getLink(item.link);
                    return (
                        <ShortcutLow
                            icon={item.icon}
                            title={item.title}
                            text={item?.text}
                            linkUrl={lnk.linkUrl}
                            linkTarget={lnk.linkTarget}
                            key={index}
                            iconPosition={iconPosition}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export function ShortcutLow(shortcut: ShortcutModel) {
    return (
        <Link
            href={shortcut.linkUrl}
            target={shortcut.linkTarget}
            className={`flex ${shortcut.iconPosition === "right" ? "justify-between" : "gap-4"} p-6 no-underline shadow rounded-lg bg-white hover:bg-brightGray`}
        >
            {shortcut.iconPosition !== "right" && shortcut.icon?.filename && (
                <img src={shortcut.icon?.filename} className={"w-10"} alt={shortcut.icon.alt} />
            )}
            <div className={`content-center`}>
                <div className={`font-bold`}>{shortcut.title}</div>
                <div className={`text-small`}>{shortcut.text}</div>
            </div>
            {shortcut.iconPosition === "right" && shortcut.icon?.filename && (
                <img src={shortcut.icon?.filename} className={"w-6 ml-4"} alt={shortcut.icon?.alt ?? "Shortcut icon"} />
            )}
        </Link>
    );
}
