"use client";
import { useState } from "react";
import { calculateMonthSavingsGraphData, calculateOutPaymentGraphData } from "movestic-core/utils/compoundIntrest";
import { ChartDataItem } from "movestic-core/components/charts/Chart.Utilites";
import * as FormatHelper from "movestic-core/utils/formatHelpers";
import { Bar } from "movestic-core/components/charts/Bar";
import { Slider } from "movestic-core/tailwind/Slider";
import { H1 } from "./Texts";
import { SecondaryButton } from "./Buttons";
import { InfoDialog } from "./InfoDialog";

export const MonthSavingsCalculation = () => {
    const [startAmount, setStartAmount] = useState(100_000);
    const [monthlyAmount, setMonthlyAmount] = useState(1_000);
    const [years, setYears] = useState(20);
    const [intrestRate, setIntrestRate] = useState(2);

    const [view, setView] = useState<"initial" | "payout">("initial");

    const graphData = calculateMonthSavingsGraphData(years, intrestRate, startAmount, monthlyAmount).map<ChartDataItem>(
        (v, i) =>
            ({
                value: v,
                label: FormatHelper.formatWithSuffix(i + 1, "år"),
                color: "var(--color-cherryRed)",
            }) as ChartDataItem,
    );

    const totalSavings = parseFloat(graphData?.slice(-1)[0]?.value.toFixed(0));
    const sliderContainerClasses = "flex justify-between mb-6 font-bold";

    return (
        <div className="p-4 bg-white rounded-lg md:p-8 text-lightBlack">
            {view === "payout" && <Payout setView={setView} totalSavings={totalSavings} />}
            {view === "initial" && (
                <>
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                        <div>
                            <div className={sliderContainerClasses}>
                                <span>Startbelopp</span>
                                <span className="font-normal">
                                    {FormatHelper.moneyFormatter(startAmount, { maximumFractionDigits: 0 })}
                                </span>
                            </div>
                            <Slider
                                min={0}
                                step={5_000}
                                max={1_000_000}
                                value={[startAmount]}
                                onValueChange={(val) => setStartAmount(val[0])}
                            />
                        </div>
                        <div>
                            <div className={sliderContainerClasses}>
                                <span>Månadssparande</span>
                                <span className="font-normal">
                                    {FormatHelper.moneyFormatter(monthlyAmount, { maximumFractionDigits: 0 })}
                                </span>
                            </div>
                            <Slider
                                min={0}
                                step={100}
                                max={10_000}
                                value={[monthlyAmount]}
                                onValueChange={(val) => setMonthlyAmount(val[0])}
                            />
                        </div>
                        <div>
                            <div className={sliderContainerClasses}>
                                <span>Spartid</span>
                                <span className="font-normal">{years} år</span>
                            </div>
                            <Slider min={1} max={50} value={[years]} onValueChange={(val) => setYears(val[0])} />
                        </div>
                        <div>
                            <div className={sliderContainerClasses}>
                                <span>Förväntad avkastning</span>
                                <span className="font-normal">{intrestRate} %</span>
                            </div>
                            <Slider
                                min={0}
                                step={1}
                                max={6}
                                value={[intrestRate]}
                                onValueChange={(val) => setIntrestRate(val[0])}
                            />
                        </div>
                    </div>
                    <div className="mt-8">
                        <Bar
                            dataSet={graphData}
                            size={150}
                            yAxisTickFormatter={(v) => FormatHelper.formatWithSuffix(v, "kr")}
                        />
                    </div>
                    <div className="flex flex-col items-center py-8">
                        <div className="font-bold">Ditt sparande efter {years} år </div>
                        <H1 className="text-cherryRed lining-nums">
                            {FormatHelper.moneyFormatter(totalSavings, { maximumFractionDigits: 0 })}
                        </H1>
                        <SecondaryButton onClick={() => setView("payout")}>
                            Hur blir det om jag tar ut pengar månadsvis?
                        </SecondaryButton>
                        <SavingModal />
                    </div>
                </>
            )}
        </div>
    );
};

const Payout = ({ setView, totalSavings }) => {
    const [payoutView, setPayoutView] = useState<"Tid" | "Belopp">("Tid");
    const [monthlyAmount, setMonthlyAmount] = useState(1_000);
    const [years, setYears] = useState(20);
    const [intrestRate, setIntrestRate] = useState(2);

    const maxAmountValue = totalSavings / 5 / 12;

    const graphData = calculateOutPaymentGraphData(
        totalSavings,
        intrestRate,
        years,
        monthlyAmount,
        payoutView === "Tid",
    ).map<ChartDataItem>(
        (v, i) =>
            ({
                value: v,
                label: FormatHelper.formatWithSuffix(i + 1, "år"),
            }) as ChartDataItem,
    );

    const sliderContainerClasses = "flex justify-between mb-6 font-bold";

    return (
        <>
            <div className="mb-4">
                <ToggleButton options={["Tid", "Belopp"]} selectedValue={payoutView} onChange={setPayoutView} />
            </div>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                <div>
                    {payoutView === "Belopp" && (
                        <>
                            <div className={sliderContainerClasses}>
                                <span>Månadsbelopp</span>
                                <span className="font-normal">
                                    {FormatHelper.moneyFormatter(monthlyAmount, { maximumFractionDigits: 0 })}
                                </span>
                            </div>
                            <Slider
                                min={Math.round(totalSavings / (40 * 12))}
                                max={maxAmountValue}
                                value={[monthlyAmount]}
                                onValueChange={(val) => setMonthlyAmount(val[0])}
                            />
                        </>
                    )}
                    {payoutView === "Tid" && (
                        <>
                            <div className={sliderContainerClasses}>
                                <span>Uttagstid</span>
                                <span className="font-normal">{years} år</span>
                            </div>
                            <Slider min={5} max={40} value={[years]} onValueChange={(val) => setYears(val[0])} />
                        </>
                    )}
                </div>
                <div>
                    <div className={sliderContainerClasses}>
                        <span>Förväntad avkastning</span>
                        <span className="font-normal">{intrestRate} %</span>
                    </div>
                    <Slider
                        min={0}
                        step={1}
                        max={6}
                        value={[intrestRate]}
                        onValueChange={(val) => setIntrestRate(val[0])}
                    />
                </div>
            </div>

            <div className="mt-8">
                <Bar
                    dataSet={graphData}
                    size={150}
                    yAxisTickFormatter={(v) => FormatHelper.formatWithSuffix(v, "kr")}
                />
            </div>

            <div className="flex flex-col items-center py-8">
                {payoutView === "Tid" && (
                    <>
                        <div className="font-bold">Din första utbetalning blir </div>
                        <H1 className="text-cherryRed lining-nums">
                            {FormatHelper.moneyFormatter(totalSavings / years / 12, { maximumFractionDigits: 0 })}
                        </H1>
                    </>
                )}
                {payoutView === "Belopp" && (
                    <>
                        <div className="font-bold">
                            {FormatHelper.formatWithSuffix(monthlyAmount, "kr", 0)} i månaden i cirka
                        </div>
                        <H1 className="text-cherryRed lining-nums">
                            {FormatHelper.formatWithSuffix(totalSavings / (monthlyAmount * 12), "år", 0)}
                        </H1>
                    </>
                )}
                <SecondaryButton onClick={() => setView("initial")}>Ändra sparande</SecondaryButton>
                <SavingModal />
            </div>
        </>
    );
};

const ToggleButton = ({ options, selectedValue, onChange }) => (
    //This is a rudimentary tailwind toggelbutton as we cant use SC-version here.

    <div className="text-center">
        <div className="inline-flex gap-1 text-center border rounded-lg border-lightGray bg-brightGray">
            {options.map((option) => (
                <button
                    tabIndex={0}
                    onClick={() => onChange(option)}
                    className={`cursor-pointer w-32 px-4 py-2 rounded-lg text-lightBlack ${option === selectedValue ? "bg-cherryMist font-bold border-[1.5px] border border-cherryRed -m-[1.5px] " : " hover:bg-cherryMist l"} font-neutraface `}
                    key={option}
                >
                    {option}
                </button>
            ))}
        </div>
    </div>
);

const SavingModal = () => {
    return (
        <InfoDialog
            title={"Så här har vi räknat"}
            trigger={<div className="m-6 mx-auto underline cursor-pointer">Så har vi räknat</div>}
        >
            <div className="flex flex-col gap-6">
                <p>
                    Uträkningen baseras på startbelopp, månadssparande, vald spartid och antagen avkastning. Den årliga
                    avkastningen beräknas som effektiv årsavkastning och realiseras varje år tillsammans med ackumulerat
                    kapital. Räkneexemplet bygger på förenklingar och är endast avsedd som ett exempel och tar inte
                    heller hänsyn till eventuella skatteeffekter eller avgifter och är beroende på utvecklingen i de
                    fonder du väljer att investera i.
                </p>
                <p className="italic">
                    Historisk avkastning är ingen garanti för framtida avkastning. En investering i värdepapper/fonder
                    kan både öka och minska i värde och det är inte säkert att du får tillbaka det investerade
                    kapitalet. Avkastningen kan också öka eller minska på grund av förändringar i valutakursen.
                </p>
            </div>
        </InfoDialog>
    );
};
