import { InputHTMLAttributes } from "react";
import { cn } from "movestic-core/utils/tailwind-utils";

export const Input: React.FunctionComponent<InputHTMLAttributes<HTMLInputElement>> = ({
    value,
    onChange,
    className,
    ...rest
}) => (
    <input
        type="text"
        value={value}
        className={cn("p-3 leading-4 border border-gray rounded-[3px] text-lightBlack placeholder-gray", className)}
        onChange={onChange}
        {...rest}
    />
);
